<template>
    <div class="keepCleanInfo">
        <!--        导航-->
        <div>
<!--            <van-nav-bar title="保洁" left-arrow @click-left="onClickLeft"/>-->
            <rx-nav-bar title="保洁完成信息"></rx-nav-bar>
        </div>
        <div class="cleanTitle">申请详情</div>
        <div class="applyDetail" >
<!--            地址信息-->
            <div class="addressInfo"> {{ applyDetailOrders.roomDetailedAddress ? applyDetailOrders.roomDetailedAddress : '暂无'}}</div>
<!--            分割线-->
            <div class="rule"></div>
<!--            详情-->
            <div class="divOne">
                <div class="divThree">
                    <p>
                        <span class="spanOne">申请人: </span>
                        <span class="spanTwo"> {{ applyDetailOrders.applicationGlobaluserName ? applyDetailOrders.applicationGlobaluserName : '暂无'  }}</span>
                    </p>
                    <p>
                        <span class="spanOne">订单来源: </span>
                        <span class="spanTwo">{{ applyDetailOrders.fromType ? applyDetailOrders.fromType : '暂无'}}</span>
                    </p>
                </div>
                <div class="divFour">
                    <p>
                        <span class="spanOne">联系方式: </span>
                        <span class="spanTwo"> {{ applyDetailOrders.applicationGlobaluserMoblile ? applyDetailOrders.applicationGlobaluserMoblile : '暂无'}}</span>
                    </p>
                    <p>
                        <span class="spanOne">紧急程度: </span>
                        <span class="spanThree">{{applyDetailOrders.distributionStatus ? applyDetailOrders.distributionStatus : '暂无'}}</span>
                    </p>
                </div>
            </div>
            <div class="divTwo">
                <p>
                    <span class="spanOne">预约时间: </span>
                    <span class="spanThree"> {{applyDetailOrders.appointmentTime ? applyDetailOrders.appointmentTime : '暂无'}}</span>
                </p>
                <p>
                    <span class="spanOne">申请原因: </span>
                    <span class="spanTwo">{{ applyDetailOrders.afterRentDesprition ? applyDetailOrders.afterRentDesprition : '暂无'}}</span>
                </p>
            </div>
        </div>
<!--        保洁信息-->
        <div class="cleanTitle">派单信息</div>
        <div class="keepCleanPerson" >
            <p>
                <span class="spanOne">保洁: </span>
                <span class="spanTwo">{{ applyDetailOrders.userName ? applyDetailOrders.userName : '暂无'}}</span>
            </p>
            <p>
                <span class="spanOne">预计上门时间: </span>
                <span class="spanThree"> {{applyDetailOrders.implementTime ? applyDetailOrders.implementTime : '暂无'}}</span>
            </p>
            <p>
                <span class="spanOne">预计费用:  </span>
                <span class="spanTwo">{{ applyDetailOrders.expectFee ? applyDetailOrders.expectFee+'元' : '暂无'}}</span>
            </p>
        </div>
<!--        承担方-->
        <div class="cleanTitle">完成信息</div>
        <div class="assume">
            <div :class="costBear==''?grayDot : redDot"></div>
            <span class="spanOne">费用承担方</span>
            <span class="vertiacl">|</span>
            <div class="downMenu" :value="costBear"  @click="getCostBear" >
                <div class="downMenuValue">{{costBear ? costBear : '请选择'}}</div>
                <div class="arrows"></div>
            </div>

        </div>
<!--        完成费用 -->
        <div class="assume">
            <div :class="lastFee === ''?grayDot : redDot"></div>
            <span class="spanOne">完成费用</span>
            <span class="vertiacl">|</span>
<!--            <span >￥</span>-->
            <input class="inputText" type="number" v-model="lastFee">
            <div class="spanTwo">元</div>
        </div>
<!--        完成 时间-->
        <div class="assume">
            <div :class="finishTime==''?grayDot : redDot"></div>
            <span class="spanOne">完成时间</span>
            <span class="vertiacl">|</span>
            <div class="inputText">{{finishTime}}</div>
            <img class="timeImg" src="../../../assets/images/calendar.png"  @click="chooseTime"/>
<!--            <van-calendar v-model="overShow" @confirm="onConfirm" />-->
        </div>
        <div class="feedback">
            <div class="feedbackText" >
                <span class="spanOne">处理反馈</span>
                <span >(非必填)</span>
            </div>
            <textarea class="feedbackTextarea" v-model="dealResult" ></textarea>
            <div class="uploadDiv">
                <!--                    :preview-image="false"    上传图片按钮-->
                <van-uploader  class="addImgBtn"  v-model="picList" multiple
                             image-fit="contain" :after-read="afterReadTest" upload-icon="plus" :max-count="10">
                </van-uploader>
            </div>

        </div>
<!--        提交按钮-->
        <button :class="!costBear||lastFee === ''||!finishTime ? graySaveBtn : redsaveBtn"
                    :disabled="!costBear||lastFee === ''||!finishTime||disabled" @click="confirmFinished">
            提交
        </button>
        <!--        ===========================费用承担方的弹窗==============================================-->
        <van-popup v-model="showPickerBearer" round position="bottom">
            <van-picker  show-toolbar :columns="bearerList" value-key="dictionaryTitle"  @cancel="showPickerBearer = false" @confirm="selectUndertakerOption"/>
        </van-popup>
<!--        ====================================完成时间的弹窗==============================-->
        <van-popup v-model="isCheckInDateShow" position="bottom">
            <div class="date-dropDownList" >
                <van-datetime-picker v-model="currentDate" type="datetime" title="选择年月日" item-height="35px" @confirm="checkInDateConfirm" @cancel="checkInDateCancel"/>
            </div>
        </van-popup>
        <!--拨打电话弹窗-->
        <loading-flag v-if="loadingFlag"></loading-flag>
    </div>
</template>

<script>
    import { NavBar,Calendar,cell,uploader,button,icon,picker,popup,DatetimePicker } from 'vant';
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import {globaluserId,getStaffId,dealImage, responseUtil} from "../../../libs/rongxunUtil";
    import {base64Upload, finishCleaningHeadList, queryBaseData, saveFinishCleaning} from "../../../getData/getData";
    import LoadingFlag from "../maintain/LoadingFlag";
    export default {
        components: {
            LoadingFlag,
            [NavBar .name]:NavBar,
            [Calendar .name]:Calendar,
            [cell .name]:cell,
            [uploader .name]:uploader,
             [button .name]:button,
            rxNavBar,
            [icon .name]:icon,
            [picker .name]:picker,
            [popup .name]:popup,
            [DatetimePicker .name]:DatetimePicker
        },
        data() {
            return {
                roleType: '1',  //标识，用于判断入口是我的页面的租后工单（装修工人等 是 0   否 1）
                lastFee : '',
                //上传图片
                loadingFlag : false ,
                //控制按钮是否可点击
                disabled : false,
                //保洁初始化数据信息
                applyDetailOrders : {},
                //保洁承担方弹窗控制
                showPickerBearer : false,
                //保洁承担方初始化数据
                bearerList : [],
                costBear : '',
                //完成时间选择弹窗控制
                isCheckInDateShow : false,
                //完成费用
                finalCost:'',
                //完成时间
                currentDate : new Date(),
                finishTime : '',
                //反馈
                dealResult : '',
                //灰点显示
                grayDot : 'grayDot',
                // 红点显示
                redDot : 'redDot',
                //提交按钮
                graySaveBtn : 'graySaveBtn',
                redsaveBtn : 'redsaveBtn',
            //    上传图片的列表
                picList : [],
                costBearId : '',
            }
        },
        created() {
            this.afterRent_id = this.$route.query.afterRent_id
            this.cleanid = this.$route.query.cleanid
            this.roleType = this.$route.query.roleType
            this.initDb()


        },
        mounted() {
            this.finishCleaningHeadList()
        },
        methods : {
            //保洁完成初始化
            finishCleaningHeadList(){
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.id = that.cleanid
                initData.afterRent_id = that.afterRent_id
                finishCleaningHeadList(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        that.applyDetailOrders = response.data.data
                        that.lastFee = that.applyDetailOrders.expectFee
                        for(let i=0;i<that.bearerList.length;i++){
                            if(that.applyDetailOrders.costBear==that.bearerList[i].dictionaryValue) {
                                that.$set(that,'costBear',that.bearerList[i].dictionaryTitle)
                                that.$set(that,'costBearId',that.bearerList[i].dictionaryValue)
                            }
                        }
                    })
                })
            },
            getCostBear(){
                this.showPickerBearer = true
            },
            //初始化字典承担方=====================================================
            initDb : function () {
                let that = this
                let initData = {}
                initData.dbName = []
                initData.fdName = ['COSTBEARMAP']
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        that.bearerList = response.data.data.COSTBEARMAP
                    })
                })
            },
            //承担方弹窗
            selectUndertakerOption(value){
                // that.costBearId
                this.costBear = value.dictionaryTitle
                this.costBearId = value.dictionaryValue
                this.showPickerBearer = false
            },
            //日期选择组件 点击确认键后执行方法
            checkInDateConfirm(value){
                var date = new Date(value)
                this.finishTime = this.formatDate(date)
                this.isCheckInDateShow = false
            },

            // 格式化日期方法
            formatDate(dateTime) {
                console.log(dateTime.getMinutes())
                let year = `${dateTime.getFullYear()}`;
                let month  = `${dateTime.getMonth() + 1}` ;
                let date = `${dateTime.getDate()}` ;
                let hours = `${dateTime.getHours()}` ; ;
                let minutes = `${dateTime.getMinutes()}` ; ;
                if(month < 10){
                    month = `0${dateTime.getMonth() + 1}`
                }
                if(date < 10){
                    date = `0${dateTime.getDate()}`
                }
                if(hours < 10){
                    hours = `0${dateTime.getHours()}`
                }
                if( minutes < 10){
                    minutes = `0${dateTime.getMinutes()}`
                }
                return year+ '/' + month+ '/' + date + ' ' + hours + ':' + minutes;
            },
            //日期选择组件 点击取消键后执行方法
            checkInDateCancel(){
                this.isCheckInDateShow = false
            },
            chooseTime(){
                this.isCheckInDateShow = true
            },
            //保存完成信息
            confirmFinished(){
                this.disabled = true
                this.saveFinishCleaning()
                setTimeout(()=>{
                    this.disabled = false
                },2000)
            },
            //保洁完成保存接口
            saveFinishCleaning(){
                let that = this
                let initData = {}
                if(that.roleType == '1'){
                    initData.user_id = getStaffId()
                }else if(that.roleType == '0'){
                    initData.user_id = '1'  //如果登录人是装修负责人（非员工），则使用固定值1
                }
                //保洁ID
                initData.id = that.cleanid
                //租后ID
                initData.afterRent_id = that.afterRent_id
                //费用承担方
                initData.description_id = that.costBearId
                //最终费用
                initData.lastFee = that.lastFee
                //完成时间
                initData.finishTime = new Date(that.finishTime)
                initData.afterRentDesprition = that.dealResult
                initData.imgList = that.picList
                saveFinishCleaning(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        responseUtil.alertMsg(that,'保洁完成提交成功')
                        that.$router.go(-1)
                    })
                })
            },

            //图片多选上传
            afterReadTest(file){
                // this.loadingFlag = true;
                debugger
                // alert(file.content.length)
                let fileLength = parseInt(file.length) - parseInt(1)
                if(file.length > 1){
                    for(let i = 0; i<file.length;i++){
                        let fileDetail = file[i];
                        dealImage(fileDetail,fileDetail.content,400,this.afterRead,i,fileLength);
                    }
                }else{
                    // this.afterRead(file);
                    dealImage(file,file.content,400,this.afterRead);
                }
            },

            afterRead(file,base64Str,i,fileLength){
                debugger
                let that = this
                let initData = {
                    base64: base64Str
                }
                that.loadingFlag = true,
                    base64Upload(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            file.path = response.data.data.path
                            // that.loadingFlag = false
                            if(i == fileLength){
                                that.loadingFlag = false
                            }
                        })
                    })
            },
        }
    }
</script>

<style lang="less" scoped >
    *{
        margin: 0;
        padding: 0;
    }
    .keepCleanInfo{
        padding-bottom: 50px;
    }
    /*公共的p和span标签*/
    .spanOne{
        font-size: 14px;
        font-weight: bold;
        color: black;
        width: 25%;
    }
    .spanTwo{
        font-size: 14px;
        color: #9a9a9a;
    }
    .spanThree{
        font-size: 13px;
        color:#FF5D3B ;
    }
    /*公共的竖线*/
    .vertiacl{
        margin: 0 10px;
        font-size: 14px;
        color: #e6e6e6;
    }
    /*公共的点*/
    .grayDot{
        border: 3px solid #9a9a9a;
        margin: 0 10px 0 15px;
        border-radius: 50%;
    }
    /*公共的小圆点*/
    .redDot{
        border: 3px solid #FF5D3B;
        margin: 0 10px 0 15px;
        border-radius: 50%;
    }
    /*公共的input*/
    .inputText{
        height:30px;
        border: none;
        width: 50%;
        line-height: 30px;
    }
    .timeImg{
        height: 26px;
        width: 26px;
        margin-left: -3px;
    }
    .divOne,.divTwo{
        width: 92%;
        margin: 0 15px;
        overflow: hidden;
        word-wrap: break-word;
    }
    .divOne{
        display: flex;
        .divThree{
            width: 46%;
        }
        .divFour{
            width: 54%;
        }

    }
    //申请详情
    .cleanTitle{
        width: 92%;
        margin: 0 auto 5PX;
        font-size: 12px;
        color: #FF5D3B;
    }
/*    申请详情的主体*/
    .applyDetail{
        width: 92%;
        margin: 0 auto 15PX;
        background-color: #ffffff;
        border-radius: 8px;
        padding-bottom: 15px;
        line-height: 24px;
        padding-top: 15px;
        .addressInfo{
            font-size: 15px;
            margin: 0 15px;
            font-weight: bold;
        }
        .rule{
            border: 0.5px solid #f7f7f7;
            margin: 15px 0;
        }
    }
    /*保洁员信息*/
    .keepCleanPerson{
        width: 84%;
        margin: 0 auto 24px;
        background-color: #ffffff;
        padding: 15px;
        line-height: 24px;
        border-radius: 8px;

    }
/*    承担方*/
    .assume{
        width: 92%;
        margin: 0 auto 15px;
        background-color: #ffffff;
        display: flex;
        height: 50px;
        line-height: 24px;
        align-items: center;
        font-size: 14px;
        border-radius: 8px;
        overflow: hidden;

    }

/*    处理反馈*/
    .feedback{
        width: 92%;
        margin: 18px auto 30px;
        /*height: 150px;*/
        border-radius: 8px;
        background-color: #fff;
        padding-top: 8px;
        padding-bottom: 0.5px;

        /*text-align: center;*/
        /*处理反馈DIV*/
        .feedbackText{
            /*margin: 18px 15px 15px;*/
            margin: 0 15px 10px;
            font-size: 13px;
            color: #9d9d9d;

        }
        .feedbackTextarea{
            width: 83%;
            display: block;
            margin: 0 auto;
            height:60px;
            padding: 15px;
            /*color: #e6e6e6;*/
            background-color: #f7f7f7 ;
            border-radius: 8px;
            border: none;
            font-size: 14px;
            resize: none;
        }

    }
    /*处理反馈的文本域*/
    textarea::placeholder{
        font-size: 13px;
        color: #d5d5d5;
    }
    input::placeholder{
        color:#d5d5d5 ;
    }
/*    上传文件*/
    .uploadDiv{
        border-radius: 8px ;
        margin: 10px auto;
        display: flex;
        .addImgBtn{
            margin-left: 10px;
        }
    }
/*    提交按钮*/
    .graySaveBtn,.redsaveBtn{
        width: 92%;
        display: block;
        margin: 0 auto ;
        height: 50px;
        border-radius: 8px;
        border: none;
        font-size: 14px;
        color: white;
    }
    /*按钮默认灰色*/
    .graySaveBtn{
        background-color:#999;
    }
    /*按钮满足条件变成蓝色*/
    .redsaveBtn{
        background-image: linear-gradient(to right,#FFC274,#FF5D3B);
    }
    //费用承担方
    .downMenu{
        width: 60% ;
        height: 30px;
        display: flex;
        align-items: center;
        .downMenuValue{
            width: 88%;
            font-size: 14px;
            color: #999;
        }
        .arrows{
            width: 0;
            height: 0;
            border: 3.5px solid;
            border-color: black transparent transparent transparent;
            margin-top: 5px;
        }
    }
    .date-dropDownList{
        /*margin-left: 15px;*/
        /*width: 92%;*/
        /*margin-right: 10px;*/
        /*position: fixed;*/
        /*bottom: 0;*/
        /*z-index: 99;*/
        /*margin-top: 244px;*/
    }
</style>
